<!-- 查看审核用户 -->
<template>
  <div class='box-card'>
    <el-form label-width="120px" class="left_box">
      <el-form-item label="手机号：">{{ detailForm.phone }}</el-form-item>
      <el-form-item label="公司名称：">{{ detailForm.company }}</el-form-item>
      <el-form-item label="负责人：">{{ detailForm.principal }}</el-form-item>
    </el-form>
    <div v-if="status === 1">
      <lbButton type="warning" icon="close" class="checkButton2" @click="isRefuseDialog = true">拒 绝</lbButton>
      <lbButton type="default" fill icon="confirm" class="checkButton" @click="fnCheckAICounter(2)">同 意</lbButton>
    </div>
    <lebo-dialog append-to-body width="25%" title="确认拒绝" :isShow="isRefuseDialog" footerSlot
      @close="isRefuseDialog = false">
      <span>确定拒绝此用户吗？</span>
      <span slot="footer" class="dialog-footer">
        <lbButton type="warning" icon="close" class="checkButton2" @click="fnCheckAICounter(3)">确认拒绝</lbButton>
      </span>
    </lebo-dialog>
  </div>
</template>
<script>
import { getAIAccountCheckDetail, checkAICounter } from '@/api/maintenanceCenter'
export default {
  name: 'checkUserDetial',
  components: {},
  props: ['id', 'status'],
  data () {
    return {
      detailForm: {
        phone: '',
        company: '',
        principal: ''
      },
      // 展示拒绝原因对话框
      isRefuseDialog: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    // 获取审核详情
    async fnGetAIAccountCheckDetail () {
      const res = await getAIAccountCheckDetail({ _id: this.id })
      console.log('审核详情', res)
      if (res.Data && res.Code === 200) {
        this.detailForm.phone = res.Data.phone
        this.detailForm.company = res.Data.company
        this.detailForm.principal = res.Data.principal
      }
    },
    // 审核AI账号
    async fnCheckAICounter (type) {
      // type =2通过   type=3拒绝
      const obj = {}
      obj._id = this.id
      obj.status = type
      const res = await checkAICounter(obj)
      console.log('审核用户', res)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    }
  },
  created () { },
  mounted () {
    this.fnGetAIAccountCheckDetail()
  }
}
</script>

<style scoped lang="less">
.box-card {
  position: relative;
  padding-bottom: 30px;

  .form_box {
    max-height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}
</style>
